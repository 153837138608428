<template>
  <div v-if="dataSource.length > 0">
    <div class="grid grid-cols-4 gap-4 mb-5 items-center" v-for="item in dataSource" :key="item.Id">
      <div class="flex gap-2 items-center">
        <Checkbox :binary="true" class="checkbox-primary" v-model="item.IsCheck" v-if="isPopUp" />
        <p class="color-blue-grey font-bold">{{ item.Nama }}</p>
      </div>
      <div class="col-span-3" v-if="item.showField">
        <div class="rounded-lg p-2 opacity-75" v-if="item.Tipe === 'DRANGE' && !isPopUp" style="border: 1px solid #ced4da; color: #495057; ">
          {{`${moment(item.ValueChoice[0]).format('DD/MM/YYYY')} - ${moment(item.ValueChoice[1]).format('DD/MM/YYYY')}`}}
          <!-- {{moment()}} -->
        </div>
        <Calendar selectionMode="range" :touchUI="true" v-model="item.ValueChoice" class="w-full rounded-lg" panelClass="calendar-add-prams-form" :manualInput="false" v-if="item.Tipe === 'DRANGE' && isPopUp" :placeholder="`Pilih ${item.Nama}`" :disabled="!item.IsCheck || !isPopUp" :keepInvalid="true" :showIcon="true" :showButtonBar="true" icon="pi pi-calendar" />
        <InputNumber :min="0" class="input-primary rounded-lg w-full" mode="currency" currency="IDR" currencyDisplay="code" locale="id-ID" v-model="item.ValueChoice" :placeholder="`Pilih ${item.Nama}`" v-else-if="item.Tipe === 'NUMERIC'" :disabled="!item.IsCheck || !isPopUp" />
        <Multiselect v-model="item.ValueChoice" :searchable="true" class="input-primary w-full rounded-lg" mode="tags" :options="item.ResultFetch" :placeholder="`Pilih ${item.Nama}`" v-else-if="item.Tipe === 'CSV'" :disabled="!item.IsCheck || !isPopUp" />
        <div class="flex gap-4 items-center" v-if="item.Tipe === 'TRANGE'">
          <Calendar v-model="item.ValueChoice[0]" class="w-full rounded-lg" :timeOnly="true" :keepInvalid="true" hourFormat="24" :showTime="true" :placeholder="`Waktu Mulai`" :disabled="!item.IsCheck || !isPopUp" :showButtonBar="true" :showIcon="true" icon="pi pi-clock" />
          <p class="color-blue-grey font-bold">s.d.</p>
          <Calendar v-model="item.ValueChoice[1]" class="w-full rounded-lg" :timeOnly="true" :keepInvalid="true" hourFormat="24" :showTime="true" :placeholder="`Waktu Selesai`" :disabled="!item.IsCheck || !isPopUp" :showButtonBar="true" :showIcon="true" icon="pi pi-clock" />
        </div>
        <!-- <p>{{item.ResultFetch}}</p> -->
      <!-- {{item}} -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PramsComponents',
  props: ['dataSource', 'isPopUp'],
  data() {
    return {
      moment
    }
  }
}
</script>

<style>
  /* .p-datepicker .p-component .calendar-add-prams-form .p-datepicker-touch-ui {
    z-index: ;
  } */
</style>
