import {
  ref, onMounted, getCurrentInstance, reactive, watch, watchEffect
} from 'vue'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import { Remote, remoteEnum } from '@/data/source/remote/remote'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import { useStore } from 'vuex'
import PramsComponents from '@/views/master/transactions/voucher-discount/form/PramsComponents.vue'
import {
  cloneDeep
} from 'lodash'

const modules = 'voucher'

export default {
  name: 'AdditionalParamsForm',
  components: {
    PramsComponents
  },
  props: {
    listForm: {
      default: () => []
    },
    isPopUp: {
      default: () => true
    }
  },
  emits: ['sendPreferredData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { listForm, isPopUp } = reactive(props)
    const store = useStore()
    const dataSource = ref([])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const funcResponse = (prams: any): Promise<Response> => new Repository(prams, null).getResult(false)

    const funcProcessGetUrl = async (urlFetch: any) => {
      const response = await funcResponse(new Remote(remoteEnum.get, urlFetch))
      console.log('res', response)
      const result = await response.result
      console.log('result', result)
      return result
    }

    const mappingListAdditionalParams = async (dataArr: any) => {
      const arr = '@Array'
      const hst = '@Host'
      for (let i = 0; i < dataArr.length; i++) {
        // dataArr.forEach(async (node: any, i: any) => {
        const node = dataArr[i]
        node.ResultFetch = null
        node.IsCheck = false
        node.ValueChoice = null
        node.showField = false
        if (node.Tipe === 'TRANGE') {
          node.ValueChoice = [null, null]
        }
        if (node.UrlFetch !== null) {
          console.log('url', node.UrlFetch)
          if (node.UrlFetch.includes(hst)) {
            console.log('hst')
            let hasil = null
            /* eslint-disable-next-line no-await-in-loop */
            hasil = await funcProcessGetUrl(node.UrlFetch.replace(hst, '')) as any
            console.log('hasil', hasil)
            if (!Array.isArray(hasil)) {
              hasil = hasil.Data
            }
            node.ResultFetch = hasil.map((x: any) => {
              x.label = node.Kode === 'HR' ? x.Kode : x.Nama
              x.value = x.Id
              return x
            })
            console.log(`node host ->${i}`, node)
          }
          if (node.UrlFetch.includes(arr)) {
            console.log('arr')
            node.ResultFetch = JSON.parse(node.UrlFetch.replace(arr, '')).map((x: any) => ({
              label: x,
              value: x
            }))
            // console.log(`any node array ->${i}`, node)
          }
        }
        node.showField = true
      }
      return dataArr
    }

    const fetchVoucherPrams = async () => {
      await store.dispatch('showLoading')
      const response = await voucherDiscountUseCase.getVoucherPram()
      dataSource.value = await mappingListAdditionalParams(response.result)
      await store.dispatch('hideLoading')
      // console.log('data source ', dataSource.value)
    }

    const submitValue = () => {
      const dataSubmit = dataSource.value.filter((x: any) => x.IsCheck === true && x.ValueChoice !== null)
      if (dataSubmit.length > 0) {
        // store.dispatch(`${modules}/setVoucherDetails`, dataSubmit)
        emit('sendPreferredData', dataSubmit)
        // dataSource.value = dataSubmit
      }
      console.log('ini data submit', dataSubmit)
    }

    const updateValueForm = () => {
      dataSource.value = listForm
    }

    const submitReturn = () => {
      const dataSubmit = dataSource.value.filter((x: any) => x.IsCheck === true && x.ValueChoice !== null)
      return dataSubmit
    }

    const checkIsValidate = () => {
      const dataSubmit = dataSource.value.filter((x: any) => x.IsCheck === true && x.ValueChoice !== null)
      let isValidate = true
      dataSubmit.map((x: any) => {
        if (x.Tipe === 'DRANGE') {
          if (x.ValueChoice[1] === null) {
            isValidate = false
          }
        }
        return x
      })
      return isValidate
    }

    onMounted(() => {
      if (listForm.length > 0) {
        dataSource.value = listForm
      } else {
        fetchVoucherPrams()
      }
    })
    return {
      dataSource,
      submitValue,
      listForm,
      isPopUp,
      submitReturn,
      updateValueForm,
      checkIsValidate
    }
  }
}
